var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("div", { staticClass: "secondary--text" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Tasks"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.fetchingUserTasks, icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.fetchTasks()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("refresh")])],
                1
              ),
            ],
            1
          ),
          _vm.fetchingUserTasks
            ? _c("span", { staticClass: "caption text-uppercase" }, [
                _vm._v("fetching tasks data..."),
              ])
            : _c("span", { staticClass: "caption text-uppercase" }, [
                _vm._v(_vm._s(_vm.totalTasks) + " tasks found"),
              ]),
        ]),
      ]),
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "d-flex align-center flex-wrap" },
          _vm._l(_vm.taskCheckBoxData, function (taskType, key) {
            return _c(
              "div",
              { key: key },
              [
                _c("v-checkbox", {
                  staticClass: "mx-4",
                  on: { change: _vm.changeSelectedTasks },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "secondary--text caption text-uppercase",
                              },
                              [_vm._v(_vm._s(taskType.label))]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: taskType.value,
                    callback: function ($$v) {
                      _vm.$set(taskType, "value", $$v)
                    },
                    expression: "taskType.value",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          [
            _c("v-data-table", {
              attrs: {
                "server-items-length": _vm.totalTasks,
                loading: _vm.fetchingUserTasks,
                headers: _vm.headers,
                items: _vm.userTasks,
                options: _vm.options,
                "item-key": "tkid",
                "footer-props": {
                  itemsPerPageOptions: _vm.itemsPerPageOptions,
                },
                "no-data-text": "No user tasks found",
              },
              on: {
                "update:options": function ($event) {
                  _vm.options = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c("v-text-field", {
                          staticClass: "mb-5",
                          attrs: {
                            "append-icon": "mdi-magnify",
                            label: "Filter",
                            "single-line": "",
                            "hide-details": "",
                            outlined: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: `item.status`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: _vm.getTaskChipColor(
                                                item.status
                                              ),
                                              left: "",
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getTaskChipIcon(item.status)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm.taskIsCancellable(item.status)
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                fab: "",
                                                small: "",
                                                elevation: "0",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelThisTask(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "red darken-2",
                                                  },
                                                },
                                                [_vm._v("close")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", { staticClass: "white--text" }, [
                              _vm._v(_vm._s(_vm.taskTypeLabel(item.status))),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.operation`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          { staticClass: "text-truncate" },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.truncateText(
                                                item.operation,
                                                60
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v(_vm._s(item.operation))])]
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.started`,
                    fn: function ({ item }) {
                      return [
                        _c("div", { staticClass: "d-flex flex-column py-3" }, [
                          item.started
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateTimeToHuman")(item.started)
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                          item.finished
                            ? _c("span", { staticClass: "caption" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Duration:")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.calculateDuration(
                                        item.finished,
                                        item.started
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `item.result`,
                    fn: function ({ item }) {
                      return [
                        _c("UserTaskOutcome", {
                          attrs: { message: _vm.getItemMessage(item) },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }